import { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Checkbox } from "primereact/checkbox";
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useReactToPrint } from 'react-to-print';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent } from 'primereact/fileupload';

// AUTH FORM
import { SunshineA } from "../../commons/authform/sunshineA";
import { CignaA } from "../../commons/authform/cignaA";
import { Molina } from "../../commons/authform/molina";
import { AetnaBHA } from "../../commons/authform/aetnaBHA";
import { AetnaDA } from "../../commons/authform/aetnaDA";

import { WellcareA } from "../../commons/authform/wellcareA";
import { Carelon } from "../../commons/authform/carelon";
import { FCC } from "../../commons/authform/fcc";
// Certification
import { Certification } from "../../commons/certification/certification";
// Assessment
import { Assessment } from "../../commons/assessment/assessment";
// Sp
import { Sp } from "../../commons/sp/sp";

// import { ExternalLinkButton } from '../../commons'
import { DownloadPDFContent } from "../../commons/CreatePDFtoContent";
import { Active, ServiceCM, FromEditScmSure } from "../../../models";


export const Edit = ({ active, scm, sure, relad }: Props) => {
    const [insuance, setInsuance] = useState<string>("Sunshine Health");
    const [content, setContent] = useState<string>('');
    const handleContentChange = (newContent: string) => {
        setContent(newContent);
    };


    const [fileSelect, setFileSelect] = useState<string>("auth");
    const itemRenderer = (item: MenuItem, select: boolean, setSelect: React.Dispatch<React.SetStateAction<boolean>>, file: string) => {
        return (
            <div className={classNames(
                'p-menuitem-content hover:cursor-pointer hover:bg-gray-200',
                fileSelect === file ? 'bg-orange-200' : 'bg-white'
            )}>
                <div className="w-full flex p-2">
                    <div
                        className="flex w-3/4 items-center pl-2"
                        onClick={(e) => item.command && item.command({ originalEvent: e, item })}
                    >
                        <i className={item.icon} />
                        <p className="pl-2">
                            {item.label}
                        </p>
                    </div>
                    <div className="w-1/4 text-right">

                        {scm?.files.map((files) => {
                            const allFiles = {
                                auth: files.auth,
                                certification: files.certification,
                                assessment: files.assessment,
                                sp: files.sp,
                                evaluation: files.evaluation,
                            };
                            const fileObject = allFiles[file];

                            if (sure === files.sure && fileObject !== undefined) {
                                return (
                                    <>  
                                        {!fileObject ? (
                                            fileSelect === file && <>
                                                <Tooltip target={`.${file}`} />
                                                <i
                                                    className={`pi pi-cloud-upload animate-bounce hover:animate-none hover:text-secondary ${file}`}
                                                    data-pr-tooltip={`Upload file to system`}
                                                    data-pr-position="top"
                                                />
                                            </>
                                        ) : (
                                            <Checkbox
                                                variant="filled"
                                                onChange={() => { setSelect(!select) }}
                                                checked={select}
                                                pt={{
                                                    box: {
                                                        className: classNames(
                                                            "border-2",
                                                            select && "border-secondary bg-secondary",
                                                            "border-blue-300 hover:border-secondary"
                                                        )
                                                    }
                                                }}
                                            />
                                        )}
                                    </>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>
        );
    };



    const items: MenuItem[] = [
        {
            label: 'Documents',
            items: [
                {
                    label: 'Auth Request',
                    icon: 'pi pi-file',
                    command: () => {
                        setFileSelect("auth");
                    },
                    template: (item) => itemRenderer(item, selectAuth, setSelectAuth, "auth")
                },
                {
                    label: 'Certification',
                    icon: 'pi pi-file',
                    command: () => {
                        setFileSelect("certification");
                    },
                    template: (item) => itemRenderer(item, selectCert, setSelectCert, "certification")
                },
                {
                    label: 'Assessment',
                    icon: 'pi pi-file',
                    command: () => {
                        setFileSelect("assessment");
                    },
                    template: (item) => itemRenderer(item, selectAssessment, setSelectAssessment, "assessment")
                },
                {
                    label: 'Service Plan',
                    icon: 'pi pi-file',
                    command: () => {
                        setFileSelect("sp");
                    },
                    template: (item) => itemRenderer(item, selectSp, setSelectSp, "sp")
                },
                {
                    label: 'Evaluation',
                    icon: 'pi pi-file',
                    command: () => {
                        setFileSelect("evaluation");
                    },
                    template: (item) => itemRenderer(item, selectEvaluation, setSelectEvaluation, "evaluation")
                }
            ],

        },
        {
            label: 'Options',
            items: [
                {
                    template: (item, options) => {
                        return (
                            <div>
                                <div className="w-full flex">
                                    <div className="w-1/2 p-2">
                                        <Button
                                            label={selectAll ? "Uncheck" : "Check all"}
                                            icon={selectAll ? "pi pi-expand" : "pi pi-check-square"}
                                            className="w-full"
                                            onClick={() => setSelectAll(!selectAll)}
                                        />
                                    </div>
                                    <div className="w-1/2 p-2">
                                        <Button
                                            label="Download"
                                            disabled={!(selectAuth || selectCert || selectAssessment || selectSp || selectEvaluation)}
                                            icon="pi pi-download"
                                            className="w-full"
                                        />
                                    </div>
                                </div>
                                <div className="w-full flex p-2">

                                    <DownloadPDFContent content={content} icon="pi pi-file-pdf" label="This document in PDF" className="p-button-text w-full" />
                                </div>


                            </div>
                        );
                    }
                }
            ],

        }
    ];

    // -- Select doc
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectAuth, setSelectAuth] = useState<boolean>(false);
    const [selectCert, setSelectCert] = useState<boolean>(false);
    const [selectAssessment, setSelectAssessment] = useState<boolean>(false);
    const [selectSp, setSelectSp] = useState<boolean>(false);
    const [selectEvaluation, setSelectEvaluation] = useState<boolean>(false);

    // ---------------------
    // const chooseOptions = { icon: 'pi pi-fw pi-upload', iconOnly: false, className: 'custom-choose-btn p-button-rounded p-button-warning' };
    //   const emptyTemplate = () => {
    //     return (
    //       <div className="text-center align-items-center flex-column ">
    //         <span style={{ color: 'var(--text-color-secondary)' }} className="text-sm">
    //           Drag and Drop File Here
    //         </span>
    //       </div>
    //     );
    //   };
    // const headerTemplate = (name, time, color, link) => {
    //     const headerTemplateService2 = (options: FileUploadHeaderTemplateOptions) => {
    //       const { className, chooseButton } = options;
    //       return (
    //         <div className={className} style={{ backgroundColor: "#3f5b9e", color: "#ffffff", display: 'flex', alignItems: 'center', padding: 5, margin: 0 }}>
    //           <div className="md:flex lg:flex w-full">
    //             <div className="w-full md:w-2/4 lg:w-2/4">
    //               <div className="w-full place-items-center">
    //                 <div className='text-2xl tracking-tight place-items-center pt-1'>
    //                   {name}
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="w-full md:w-2/4 lg:w-2/4">
    //               <div className="flex w-full place-items-center">
    //                 <div className="grid flex-grow w-2/3 pr-5">
    //                   {time !== "" && <Badge value={time} severity={color} />}

    //                 </div>
    //                 <div className="grid text-center w-1/3">
    //                   <div>
    //                     {link !== "" && <ExternalLinkButton href={link} />}

    //                   </div>
    //                 </div>
    //                 <div className="grid text-right">
    //                   <div>{chooseButton}</div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     };

    //     return (options) => headerTemplateService2(options);
    //   };
    // ---------
    useEffect(() => {
        // eslint-disable-next-line array-callback-return

        scm?.sure.map((insurance) => {
            if (insurance.active) {
                setInsuance(insurance.plan_name);
            }
        });
    }, [scm]);

    useEffect(() => {
        if (selectAll) {
            setSelectAuth(true);
            setSelectCert(true);
            setSelectAssessment(true);
            setSelectSp(true);
            setSelectEvaluation(true);
        } else {
            setSelectAuth(false);
            setSelectCert(false);
            setSelectAssessment(false);
            setSelectSp(false);
            setSelectEvaluation(false);
        }
    }, [selectAll]);
    return (
        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
            <div className="w-1/5">

                <Menu model={items} className="w-full" />
            </div>
            <div id="content-to-pdf" className="w-4/5 border-l-2 border-dashed surface-border border-round surface-ground p-5">
                {fileSelect === "auth" && <>
                    {insuance === "Sunshine Health" && <SunshineA scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "Cigna" && <CignaA scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "Molina Healthcare" && <Molina scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "Aetna Better Health" && <AetnaBHA scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "Aetna Health Plan" && <AetnaDA scm={scm} onContentChange={handleContentChange} />}
                    {/* row2 */}
                    {insuance === "Wellcare Health Plan" && <WellcareA scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "Simply Healthcare" && <Carelon scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "Humana" && <Carelon scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "HealthSun Health Plan" && <Carelon scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "CarePlus Health Plan" && <Carelon scm={scm} onContentChange={handleContentChange} />}
                    {insuance === "Free Medicaid" && <FCC scm={scm} onContentChange={handleContentChange} />}
                </>}
                {fileSelect === "certification" && <Certification active={active} relad={relad} scm={scm} onContentChange={handleContentChange} />}
                {fileSelect === "assessment" && <Assessment active={active} relad={relad} scm={scm} onContentChange={handleContentChange} />}
                {fileSelect === "sp" && <Sp active={active} relad={relad} scm={scm} view={"View"} onContentChange={handleContentChange} />}
                {fileSelect === "evaluation" && "Evaluation"}
            </div>

        </div>
    );
}
type Props = {
    active?: Active;
    scm: ServiceCM | undefined;
    sure?: number;
    relad(): void;
};